<template>
  <van-divider>全部</van-divider>
  <van-empty description="" image="default" v-if="shelvesGoods.length == 0" />
  <van-cell-group inset>
    <van-checkbox-group class="card-goods" v-model="checkedGoods" @change="checkboxChange">
      <template v-for="item in shelvesGoods" :key="item.id">
        <van-swipe-cell>
          <van-checkbox
            class="card-goods__item"
            :name="item.id"
            :label-disabled="true"
          >
            <van-card
              :title="item.goods.name"
              :desc="item.goods.summary"
              :price="formatPrice(item.goods.price)"
              :thumb="item.goods.picture"
              @click-thumb="gotoGoodsDetail(item.goodsId)"
            >
              <template #num>
                <van-stepper :name="item.id" v-model="item.goodsNum" integer @change="stepperChange" />
              </template>
            </van-card>
          </van-checkbox>
          <template #right>
            <van-button square text="删除" type="danger" class="btn-delete" @click="deleteGoods(item.id)" />
          </template>
        </van-swipe-cell>
      </template>
    </van-checkbox-group>
  </van-cell-group>

  <van-cell-group title="失效商品" inset v-if="expireGoods.length > 0">
    <template
      v-for="item in expireGoods"
      :key="item.id"
    >
      <van-swipe-cell>
        <van-card
          :num="item.goodsNum"
          :title="item.goods.name"
          :desc="item.goods.summary"
          :price="formatPrice(item.goods.price)"
          :thumb="item.goods.picture"
        />
        <template #right>
          <van-button square text="删除" type="danger" class="btn-delete" :data-key="item.id" @click="deleteGoods(item.id)" />
        </template>
      </van-swipe-cell>
    </template>
  </van-cell-group>
    
  <van-submit-bar class="cart-submit-bar"
    :price="totalPrice"
    :disabled="!checkedGoods.length"
    :button-text="submitBarText"
    @submit="gotoOrderConfirm"
  />

  <FootTabbar active="cart"></FootTabbar>
</template>

<script>
import { Toast, Dialog } from 'vant'
import FootTabbar from '../../components/foot-tabbar.vue'

export default {
  components: {
    FootTabbar
  },

  data() {
    return {
      checkedGoods: [],
      tmpCheckedGoods: [],
      goods: [],
      shelvesGoods: [],  // 有效商品
      expireGoods: [] // 失效商品
    };
  },
  
  created() {
    this.axios
      .post('/api/mall/loadCartGoodsList')
      .then(res=> {
        console.log(res.data)
        if (res.data.success && res.data.data.list.length > 0) {
          var goods = res.data.data.list
          var checkedGoods = []
          var shelvesGoods = []
          var expireGoods = []
          goods.forEach(ele => {
            if (ele.goods.status == 0) {
              expireGoods.push(ele)
            } else {
              shelvesGoods.push(ele)
              if (ele.isChecked == 1) {
                checkedGoods.push(ele.id)
              }
            }
          })
          this.goods = goods
          this.shelvesGoods = shelvesGoods
          this.expireGoods = expireGoods
          this.checkedGoods = checkedGoods
          this.tmpCheckedGoods = checkedGoods
        }
      })
  },

  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return '结算' + (count ? `(${count})` : '')
    },

    totalPrice() {
      return this.goods.reduce((total, item) => total + (this.checkedGoods.indexOf(item.id) >= 0 ? item.goods.price * item.goodsNum : 0), 0)
    }
  },

  methods: {
    formatPrice(price) {
      return (price / 100).toFixed(2)
    },

    gotoGoodsDetail(id) {
      this.$router.push({ name: 'goodsDetail', params: {id: id} })
    },

    checkboxChange(event) {
      Toast.loading({ forbidClick: true })
      let complement = this.checkedGoods.filter(val => {
        return !(this.tmpCheckedGoods.indexOf(val) > -1)
      }).concat(this.tmpCheckedGoods.filter(val => {
        return !(this.checkedGoods.indexOf(val) > -1)
      }))
      console.log("complement", complement)
      if (complement.length > 0) {
        this.axios
          .post('/api/mall/setCartGoodsChecked', {ids: complement.join()})
          .then(res=> {
            console.log(res.data)
            if (res.data.success) {
              this.goods.forEach(ele => {
                complement.forEach(val => {
                  if (ele.id == val) ele.isChecked = Math.abs(ele.isChecked-1)
                })
              })
              this.tmpCheckedGoods = event
            } else {
              this.checkedGoods = this.tmpCheckedGoods
            }
          })
      }
      Toast.clear()
    },

    stepperChange(value, detail) {
      Toast.loading({ forbidClick: true })
      this.axios
        .post('/api/mall/setCartGoodsNum', {id: detail.name, num: value})
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            
          }
        })
      Toast.clear()
    },

    gotoOrderConfirm() {
      Toast.loading({ forbidClick: true })
      let order = []
      this.shelvesGoods.forEach(ele => {
        if (ele.isChecked == 1) {
          var item = {
            goods: ele.goodsId,
            num: ele.goodsNum
          }
          order.push(item)
        }
      })
      localStorage.setItem('order', JSON.stringify(order))
      this.$router.push({ name: 'orderConfirm' })
      Toast.clear()
    },

    deleteGoods(id) {
      Toast.loading({ forbidClick: true })
      console.log(id)
      this.axios
        .post('/api/mall/deleteCartGoods', { id: id })
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            this.goods = this.goods.filter(ele => {
              return ele.id != id
            })
            this.expireGoods = this.expireGoods.filter(ele => {
              return ele.id != id
            })
            this.checkedGoods = this.checkedGoods.filter(ele => {
              return ele != id
            })
            this.tmpCheckedGoods = this.tmpCheckedGoods.filter(ele => {
              return ele != id
            })
          }
        })
      Toast.clear()
    }
  }
};
</script>

<style lang="less">
.van-cell-group {
  margin-top: 2%;

  .card-goods {

    &__item {
      position: relative;
      background-color: #fff;

      .van-card {
        background-color: #fff;
      }

      .van-checkbox__label {
        width: 100%;
        height: auto; // temp
        padding: 0 10px 0 15px;
        box-sizing: border-box;
      }

      .van-checkbox__icon {
        top: 50%;
        left: 10px;
        z-index: 1;
        position: absolute;
        margin-top: -10px;
      }

      .van-card__price {
        color: #f44;
      }
    }
  }

  .btn-delete {
    height: 100%;
  }
}

.cart-submit-bar {
  bottom: 3rem;
}
</style>